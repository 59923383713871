import { updateDoc, doc, setDoc, deleteDoc } from 'firebase/firestore';
import { Auth } from 'firebase/auth';
import { db } from '../../App';
import { IBowelMovement } from '../../Context/BowelMovementContext';

export const handleCreateBowelMovement = async (
  object: IBowelMovement,
  auth: Auth
) => {
  const id = Date.now();
  try {
    await setDoc(
      doc(db, `users/${String(auth.currentUser?.uid)}/bowelMovements`, `${id}`),
      {
        id: id,
        consistency: object.consistency,
        amount: object.amount,
        date: object.date,
        notes: object.notes,
      }
    );
  } catch (e) {
    console.log(e);
  }
};

export const handleEditBowelMovement = async (
  object: IBowelMovement,
  auth: Auth
) => {
  try {
    await updateDoc(
      doc(
        db,
        `users/${String(auth.currentUser?.uid)}/bowelMovements`,
        `${object.id}`
      ),
      {
        consistency: object.consistency,
        amount: object.amount,
        date: object.date,
        notes: object.notes,
      }
    );
  } catch (e) {
    console.log(e);
  }
};

export const DeleteBowelMovement = async (
  object: IBowelMovement,
  auth: Auth
) => {
  try {
    const docRef = doc(
      db,
      `users/${String(auth.currentUser?.uid)}/bowelMovements/${String(
        object.id
      )}`
    );
    await deleteDoc(docRef);
  } catch (e) {
    console.log(e);
  }
};
