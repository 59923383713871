import React, { useContext } from 'react';
import { getAuth, signOut } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';
import ReportBowelMovement from './ReportBowelMovement';
import {
  SetEditBowelMovementContext,
  SetLoadingContext,
  ShowModalContext,
} from '../App';

//import { useOutsideClick } from './OutsideClick';

export interface INavbarProps {}

const Navbar: React.FunctionComponent<INavbarProps> = () => {
  const { showModal, setShowModal } = useContext(ShowModalContext);
  const { editBowelMovementGlobal, setEditBowelMovementGlobal } = useContext(
    SetEditBowelMovementContext
  );
  const { setLoading } = useContext(SetLoadingContext);
  const auth = getAuth();
  const navigate = useNavigate();

  const handleMenuClick = () => {
    document.querySelector('#user-dropdown')?.classList.toggle('hidden');
  };

  // const componentRef = useRef();
  // useOutsideClick(componentRef.current!, () => {
  //   document.querySelector('#user-dropdown')?.classList.toggle('hidden');
  // });

  const handleAddBowelForm = () => {
    if (showModal === false) {
      setShowModal(true);
    } else {
      setShowModal(false);
    }
  };
  const signout = () => {
    signOut(auth);
    navigate('/sign-in');
  };

  return (
    <nav className='bg-white border-gray-200 px-2 sm:px-4 py-3 dark:bg-gray-900 '>
      <div
        className='container flex items-center justify-center mx-auto'
        // ref={componentRef as any}
      >
        {/* Plus menu dropdown */}
        <div className='flex-1 items-center justify-center'>
          <ReportBowelMovement editBowelMovement={editBowelMovementGlobal} />
          <button
            data-collapse-toggle='add-bowel-movement'
            type='button'
            className='inline-flex items-center p-2 ml-1 text-sm text-gray-500 rounded-lg hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600'
            aria-controls='add-bowel-movement'
            aria-expanded='false'
            onClick={(e) => {
              e.preventDefault();
              setEditBowelMovementGlobal(false);
              setLoading(true);
              handleAddBowelForm();
            }}
            disabled={showModal}
          >
            <span className='sr-only'>Open main menu</span>
            <svg
              xmlns='http://www.w3.org/2000/svg'
              fill='none'
              viewBox='0 0 24 24'
              stroke-width='1.5'
              stroke='currentColor'
              className='w-6 h-6'
            >
              <path
                stroke-linecap='round'
                stroke-linejoin='round'
                d='M12 4.5v15m7.5-7.5h-15'
              />
            </svg>
          </button>
        </div>
        <button
          id='dropdownTopButton'
          data-dropdown-toggle='dropdownTop'
          data-dropdown-placement='top'
          className=' text-white focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-4 py-2.5 text-center inline-flex items-center'
          type='button'
          onClick={handleMenuClick}
        >
          <span className='sr-only'>Open user menu</span>
          <img
            className='w-8 h-8 rounded-full'
            src={String(auth.currentUser ? auth.currentUser?.photoURL : '')}
            alt='user avatar'
          />
        </button>
        <div
          className={
            'z-10 hidden focus:divide-y bg-black divide-gray-100 rounded-lg bottom-12 right-10 absolute shadow w-44 dark:bg-gray-700'
          }
          id='user-dropdown'
        >
          <div className='px-4 py-3 '>
            <span className='block text-sm text-gray-900 dark:text-white'>
              {auth.currentUser ? auth.currentUser.displayName : ''}
            </span>
            <span className='block text-sm font-medium text-gray-500 truncate dark:text-gray-400'>
              {auth.currentUser ? auth.currentUser.email : ''}
            </span>
          </div>
          <ul className='py-2' aria-labelledby='user-menu-button'>
            <li>
              <button
                className='block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:hover:bg-gray-600 dark:text-gray-200 dark:hover:text-white'
                onClick={(e) => {
                  e.preventDefault();
                  signout();
                }}
              >
                Sign out
              </button>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
