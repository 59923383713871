import React, { useState, useEffect, useContext } from 'react';
import { getAuth } from 'firebase/auth';
import {
  db,
  SetEditBowelMovementContext,
  SetLoadingContext,
  ShowModalContext,
} from '../App';
import { getDocs, collection } from 'firebase/firestore';

import {
  IBowelMovement,
  BowelMovementContext,
  BowelMovementContextType,
} from '../Context/BowelMovementContext';
import { DeleteBowelMovement } from './Functions/ReportBowelMovementFunctions';

export interface IBowelMovementProps {}

const BowelMovement: React.FunctionComponent<IBowelMovementProps> = () => {
  const auth = getAuth();
  const [bowelMovements, setBowelMovements] = useState<IBowelMovement[]>([]);
  const { loading, setLoading } = useContext(SetLoadingContext);
  const { showModal, setShowModal } = useContext(ShowModalContext);
  const { saveMovement } = useContext(
    BowelMovementContext
  ) as BowelMovementContextType;
  const { setEditBowelMovementGlobal } = useContext(
    SetEditBowelMovementContext
  );

  const getBowelMovements = async () => {
    try {
      const getAllDocs = await getDocs(
        collection(db, `users/${String(auth.currentUser?.uid)}/bowelMovements`)
      );
      setBowelMovements(
        getAllDocs.docs.map((doc) => doc.data()) as IBowelMovement[]
      );
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    if (!loading) {
      if (bowelMovements.length < 1 && !bowelMovements) {
        setLoading(true);
      }
    } else {
      getBowelMovements();
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  });

  const handleDeleteBowelMovement = async (object: IBowelMovement) => {
    await DeleteBowelMovement(object, auth);
    getBowelMovements();
  };

  const addBowelMovementToContext = async (object: IBowelMovement) => {
    saveMovement(object);
  };

  const handleEditBowelForm = async (object: IBowelMovement) => {
    addBowelMovementToContext(object);
    if (showModal === false) {
      setLoading(true);
      setShowModal(true);
    } else {
      setShowModal(false);
    }
  };

  return (
    <div className='bowel-movement-container'>
      {bowelMovements && bowelMovements.length > 0
        ? bowelMovements.map((item) => (
            <div className='bg-blue-900 text-black mx-1 my-1 rounded text-left  md:mb-4'>
              <h2 className='mb-2 mx-1 text-lg font-semibold text-gray-900 dark:text-gray-100'>
                Bowel Report: {item.date}
              </h2>
              <ul className='max-w-md mx-2 my-2 space-y-1 font-semibold text-gray-100 list-disc list-inside dark:text-gray-100'>
                <li>Consistency: {item.consistency}</li>
                <li>Amount: {item.amount}</li>
                <li>Notes: {item.notes}</li>
              </ul>
              <button
                className='bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full'
                onClick={() => {
                  handleDeleteBowelMovement(item);
                }}
              >
                Delete
              </button>
              <button
                className='bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full'
                disabled={showModal}
                onClick={() => {
                  handleEditBowelForm(item);
                  setEditBowelMovementGlobal(true);
                }}
              >
                Edit
              </button>
            </div>
          ))
        : null}
    </div>
  );
};

export default BowelMovement;
