import { Fragment, createContext, useState } from 'react';
import './App.css';
import { initializeApp } from 'firebase/app';
import firebaseConfig from './firebase.config';
import SignIn from './Components/SignIn';
import Home from './Components/Home';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import AuthRoute from './Context/AuthRoute';
import { getFirestore } from '@firebase/firestore';
import BowelMovementProvider from './Context/BowelMovementContext';

const firebaseapp = initializeApp(firebaseConfig);
export const db = getFirestore(firebaseapp);

export const ShowModalContext = createContext({
  showModal: false,
  setShowModal: (showModal: boolean) => {},
});

export const SetLoadingContext = createContext({
  loading: true,
  setLoading: (loading: boolean) => {},
});

export const SetEditBowelMovementContext = createContext({
  editBowelMovementGlobal: false,
  setEditBowelMovementGlobal: (editBowelMovementGlobal: boolean) => {},
});

function App() {
  const [showModal, setShowModal] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [editBowelMovementGlobal, setEditBowelMovementGlobal] =
    useState<boolean>(false);

  return (
    <Router>
      <Fragment>
        <section>
          <ShowModalContext.Provider value={{ showModal, setShowModal }}>
            <SetLoadingContext.Provider value={{ loading, setLoading }}>
              <SetEditBowelMovementContext.Provider
                value={{ editBowelMovementGlobal, setEditBowelMovementGlobal }}
              >
                <BowelMovementProvider>
                  <Routes>
                    <Route
                      path='/'
                      element={
                        <AuthRoute>
                          <Home />
                        </AuthRoute>
                      }
                    />
                    <Route path='/sign-in' element={<SignIn />} />
                  </Routes>
                </BowelMovementProvider>
              </SetEditBowelMovementContext.Provider>
            </SetLoadingContext.Provider>
          </ShowModalContext.Provider>
        </section>
      </Fragment>
    </Router>
  );
}

export default App;
