import * as React from 'react';

export interface IBowelMovement {
  id?: string;
  consistency?: string;
  amount?: string;
  date?: string;
  notes?: string;
}
export type BowelMovementContextType = {
  Movements: IBowelMovement;
  saveMovement: (object: IBowelMovement) => void;
};

export const BowelMovementContext =
  React.createContext<BowelMovementContextType | null>(null);

const BowelMovementProvider: React.FC<React.ReactNode> = ({ children }) => {
  const [Movements, setMovement] = React.useState<IBowelMovement>({
    id: String(Date.now()),
    consistency: '1',
    amount: '1',
    date: new Date().toLocaleString(['sv-SE'], {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
    }),
    notes: '',
  });
  const saveMovement = (object: IBowelMovement) => {
    const newMovements: IBowelMovement = {
      id: object.id,
      consistency: object.consistency,
      amount: object.amount,
      date: object.date,
      notes: object.notes,
    };
    setMovement(newMovements);
  };

  return (
    <BowelMovementContext.Provider value={{ Movements, saveMovement }}>
      {children}
    </BowelMovementContext.Provider>
  );
};

export default BowelMovementProvider;
