import React, { useContext, useEffect } from 'react';
import {
  ShowModalContext,
  SetLoadingContext,
  SetEditBowelMovementContext,
} from '../App';
import { getAuth } from 'firebase/auth';

import DateElement from './Elements/DateElement';
import StringElement from './Elements/StringElement';
import SingleSelectElement from './Elements/SingleSelectElement';

import {
  BowelMovementContext,
  BowelMovementContextType,
} from '../Context/BowelMovementContext';

import {
  handleCreateBowelMovement,
  handleEditBowelMovement,
} from './Functions/ReportBowelMovementFunctions';

const ReportBowelMovement: React.FunctionComponent<{
  editBowelMovement: boolean;
}> = (props: { editBowelMovement: boolean }) => {
  const { showModal, setShowModal } = useContext(ShowModalContext);
  const { loading, setLoading } = useContext(SetLoadingContext);
  const { Movements, saveMovement } = useContext(
    BowelMovementContext
  ) as BowelMovementContextType;
  const { editBowelMovementGlobal } = useContext(SetEditBowelMovementContext);

  const auth = getAuth();

  useEffect(() => {
    setShowModal(showModal || false);
    if (loading) {
      renderMovements();
    }
  });

  const renderMovements = () => {
    setBowelMovement({
      id: String(Movements.id),
      consistency: String(Movements.consistency),
      amount: String(Movements.amount),
      date: String(Movements.date),
      notes: String(Movements.notes),
    });
  };

  const [bowelMovement, setBowelMovement] = React.useState({
    id: Movements.id ? Movements.id : '1',
    consistency: Movements.consistency ? Movements.consistency : '1',
    amount: Movements.amount ? Movements.amount : '1',
    date: Movements.date
      ? Movements.date
      : new Date().toLocaleString(['sv-SE'], {
          year: 'numeric',
          month: 'numeric',
          day: 'numeric',
          hour: '2-digit',
          minute: '2-digit',
        }),
    notes: Movements.notes ? Movements.notes : 'Write notes...',
  });

  const consistencyOption = [
    { value: '1', label: 'Separate hard lumps' },
    { value: '2', label: 'Lumpy and sausage-shaped' },
    { value: '3', label: 'Sausage with cracks on surface' },
    { value: '4', label: 'Sausage or snake, smooth' },
    { value: '5', label: 'Soft blobs with clear-cut edges' },
    { value: '6', label: 'Fluffy pieces with ragged edges' },
    { value: '7', label: 'Liquid, no solid pieces' },
  ];

  const amountOption = [
    { value: '1', label: '1 - Very little' },
    { value: '2', label: '2' },
    { value: '3', label: '3 - Normal' },
    { value: '4', label: '4' },
    { value: '5', label: '5 - A lot' },
  ];

  // Handle when button is clicked
  const handleOnSubmit = async () => {
    if (editBowelMovementGlobal) {
      await handleEditBowelMovement(bowelMovement, auth);
    } else {
      await handleCreateBowelMovement(bowelMovement, auth);
    }

    handleOnClose();
    setShowModal(false);
    setLoading(true);
  };

  // When form is closed / exited
  const handleOnClose = () => {
    if (bowelMovement) {
      saveMovement({
        id: String(Date.now()),
        consistency: '1',
        amount: '1',
        date: new Date().toLocaleString(['sv-SE'], {
          year: 'numeric',
          month: 'numeric',
          day: 'numeric',
          hour: '2-digit',
          minute: '2-digit',
        }),
        notes: '',
      });
    }
    setShowModal(false);
  };

  return (
    <>
      {showModal ? (
        <>
          <div className='justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none text-center'>
            <div className='relative w-auto my-6 mx-auto max-w-3xl'>
              {/*content*/}
              <div className='border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none'>
                {/*header*/}
                <div className='flex items-start justify-center p-5 border-b border-solid border-slate-200 rounded-t'>
                  <h3 className='text-3xl font-semibold'>
                    Report Bowel Movement
                  </h3>
                  <button
                    className='p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none'
                    onClick={() => setShowModal(false)}
                  >
                    <span className='bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none'>
                      ×
                    </span>
                  </button>
                </div>
                {/*body*/}
                <div className='relative p-6 flex-auto space-y-3'>
                  <SingleSelectElement
                    label='Consistency'
                    value={bowelMovement.consistency}
                    name='consistency'
                    options={consistencyOption}
                    onChange={(newValue) =>
                      setBowelMovement({
                        ...bowelMovement,
                        consistency: newValue,
                      })
                    }
                  />
                  <SingleSelectElement
                    label='Amount'
                    value={bowelMovement.amount}
                    name='amount'
                    options={amountOption}
                    onChange={(newValue) =>
                      setBowelMovement({ ...bowelMovement, amount: newValue })
                    }
                  />
                  <StringElement
                    label='Notes'
                    value={bowelMovement.notes}
                    name='notes'
                    onChange={(newValue) =>
                      setBowelMovement({ ...bowelMovement, notes: newValue })
                    }
                  />
                  <DateElement
                    label='Choose Date'
                    value={bowelMovement.date}
                    name='date'
                    onChange={(newValue) =>
                      setBowelMovement({ ...bowelMovement, date: newValue })
                    }
                  />
                </div>
                {/*footer*/}
                <div className='flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b'>
                  <button
                    className='text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150'
                    type='button'
                    onClick={handleOnClose}
                  >
                    Close
                  </button>
                  <button
                    className='bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150'
                    type='button'
                    onClick={handleOnSubmit}
                  >
                    Save Changes
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className='opacity-25 fixed inset-0 z-40 bg-black'></div>
        </>
      ) : null}
    </>
  );
};

export default ReportBowelMovement;
