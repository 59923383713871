const firebaseConfig = {
  apiKey: 'AIzaSyCrqLZ9J1tdGrQDneZJY3QPzMjW_qjJKLc',
  authDomain: 'health-tracker-react-8f249.firebaseapp.com',
  projectId: 'health-tracker-react-8f249',
  storageBucket: 'health-tracker-react-8f249.appspot.com',
  messagingSenderId: '684601915582',
  appId: '1:684601915582:web:be80d6bcd60226133146ef',
  measurementId: 'G-T513W066ED',
};

export default firebaseConfig;
