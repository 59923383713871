import React from 'react';
import Navbar from './Navbar';
import BowelMovement from './BowelMovement';

export interface IHomeProps {}

const Home: React.FunctionComponent<IHomeProps> = () => {
  return (
    <div className='home-container flex flex-col mb-16'>
      <div>Home</div>
      <div>
        <BowelMovement />
      </div>
      <div className='fixed inset-x-0 bottom-0 h-16'>
        <Navbar />
      </div>
    </div>
  );
};

export default Home;
