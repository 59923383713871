import React from 'react';

interface StringElementProps {
  label: string;
  value: string;
  name: string;
  onChange: (newValue: string) => void;
}

const StringElement: React.FC<StringElementProps> = ({
  label,
  value,
  name,
  onChange,
}) => {
  const handleStringChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChange(event.target.value);
  };
  return (
    <div className='relative'>
      <label
        className='block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2'
        htmlFor={name}
      >
        {label}
      </label>
      <input
        type='text'
        className='block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500'
        name={name}
        value={value}
        onChange={handleStringChange}
      ></input>
    </div>
  );
};

export default StringElement;
